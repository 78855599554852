const messages = {
  "label.login": "Войти",
  "label.logout": "Выйти",
  "label.createAccount": "Создать аккаунт",
  "label.register": "Зарегистрироваться",
  "label.name": "Имя",
  "label.email": "Email",
  "label.password": "Пароль",
  "label.yes": "Да",
  "label.no": "Нет",

  "label.layers": "Слои",
  "label.files": "Файлы",
  "label.projects": "Проекты",
  "label.image": "изображение",
  "label.text": "Текст",
  "label.loading": "Загрузка...",
  "label.save": "Сохранить",
  "label.saveAs": "Сохр. как",
  "label.cancel": "Отментиь",
  "label.title": "Название",
  "label.required": "Обязательно",
  "label.frontSide": "Передняя",
  "label.backSide": "Задняя",
  "label.select": "Выбрать",
  "label.create": "Создать",
  "label.delete": "Удалить",
  "label.selectModel": "Выберите модель",

  "label.product": "Продукт",
  "label.object": "Объект",
  "label.catalog": "Каталог",
  "label.createOwn": "Создать свой",
  "label.color": "Цвет",
  "label.price": "Цена",
  "label.size": "Размер",
  "label.quantity": "Kоличество",
  "label.total": "Общее",
  "label.order": "Заказать",

  "label.phone": "Номер телефона",
  "label.send": "Отправить",

  "label.centerVertically": "Центрировать по вертикали",
  "label.centerHorizontally": "Центрировать по горизонтали",
  "label.flipHorizontally": "Отразить по горизонтали",
  "label.flipVertically": "Отразить по вертикали",
  "label.layerUp": "Слой вверх",
  "label.layerDown": "Слой вниз",
  "label.rotate": "Поворот",
  "label.fontStyle": "Стиль фонта",
  "label.fontFamily": "Фонт",
  "label.byAgreement": "По соглашению",

  "text.loadingAppState": "Загрузка состояния приложения",
  "text.selectObjectForCustomization":
    "Выберите объект для редактирования его параметров",
  "text.loginForProjects": "Чтобы сохранить/загрузить проект нужно",
  "text.notSavedProjects": "У вас еще нет сохраненных проектов",
  "text.confirmDelete": "Подтверждаете удаление ?",
  "text.projectName": "Название проекта",
  "text.applicationAccepted": "Заявка принята",
  "text.thanksApplicationAccepted": "Спасибо, ваша заявка принята!",
  "text.weWillContactYou": "Мы свяжемся с вами в ближайшее время.",
  "text.orderBeingProcessed": "Пожалуйста подождите, ваш заказ обрабатывается.",
  "text.canTakeSometime": "Это может занять некоторое время.",
  "text.successfullyCreated": "Успещно создан",
  "text.successfullyDeleted": "Успещно удалена",
  "text.successfullyUpdated": "Успещно обновлен",
  "text.successfullyloggedIn": "Вы успешно авторизованы",

  "text.error.emptyName": "Имя не может быть пустым",
  "text.error.emptyTitle": "Название не может быть пустым",
  "text.error.emptyPhone": "Заполните номер телефона",
  "text.error.invalidEmail": "Неверный e-mail",
  "text.error.shortPassword": "Пароль должен содержать не менее 8 символов",
  "text.error.cantUploadFile": "Не удалось загрузить файл на сервер",
  "text.error.errorOccurred": "Произошла ошибка. Повторите попытку позже",
  "text.error.invalidEmailOrPassword": "Неверный email или пароль",
  "text.error.emailAlreadyUsed": "Пользователь с таким email уже существует",

  xxx: "yyy",
};

export default messages;
